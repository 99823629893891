.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem ;
  background-color: rgba(255, 255, 255, 0.7);
  /* position: fixed; 
  top: 0; 
  width:100%;
  margin-bottom: 1rem;
  z-index: 5; */
  /* background-color: black; */
}

.navbar__links {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.navbar__links__logo img {
  width: auto;
  height: 50px;
}
.navbar__links__container {
  display: flex;
  flex-direction: row;
}

.navbar__sign {
width:90px;
}
.navbar__links__container p,
.navbar__sign p,
.navbar__menu__container p {
    /* color:#fff; */
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}
.navbar__menu__container p{
    color: white;
}
.navbar__links__container p:hover,
.navbar__sign p:hover
{
    color:rgb(7, 49, 92);
}
.navbar__menu__container p:hover {
    color:rgb(37, 89, 141);
}
.navbar__sign button,
.navbar__menu__container button {
  padding: 0.5rem 1rem;
  color: #fff;
  background: #ff4820;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
}

.navbar__menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.navbar__menu svg {
  cursor: pointer;
}

.navbar__menu__container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-footer);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.navbar__menu__container p {
  margin: 1rem 0;
}

.navbar__menu__container__links__sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .navbar__links__container {
    display: none;
  }

  .navbar__menu {
    display: flex;
  }
  .navbar__sign button {
    margin-right: 10px;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 2rem 4rem;
  }
  .navbar__sign {
    display: block;
    gap: 20px;
  }
  .navbar__sign button {
    margin-right: 10px;
  }

  .navbar__menu {
    margin-left: 10px;
  }
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }

  

  .navbar__menu__container {
    top: 20px;
  }

  .navbar__menu__container__links__sign {
    display: block;
  }
}
.company__linkedin:hover{
color:"white"
}