.who_are_we {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: var(--color-footer);
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgb(20, 79, 143) 100%
  );
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgb(20, 79, 143) 100%
  );
  background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgb(20, 79, 143) 100%
  );
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgb(21, 88, 161) 100%
  );
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgb(20, 79, 143) 100%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.who_are_we__feature {
  display: flex;
  margin: 2rem 0 3rem;
}

.who_are_we__feature .jdbservices__container__feature {
  margin: 0;
  
}

.who_are_we__feature .jdbservices__container__feature-text {
  max-width: 700px;
}
.who_are_we__feature .jdbservices__container__feature-text p{
  max-width: 700px;
  font-size: 16px;
}
.who_are_we__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 4rem 0 2rem;
}

.who_are_we__heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 510px;
}

.who_are_we__heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
}

.who_are_we__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 16px;
  margin-top: 2rem;
}

.who_are_we__container .jdbservices__container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}
.who_are_we__container .jdbservices__container__feature-text {
  margin-top: 0.5rem;
}
.who_are_we__container .jdbservices__container__feature-text p{
  font-size: 16px;
}
@media screen and (max-width: 850px) {

  .who_are_we__heading p {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .who_are_we__feature .jdbservices__container__feature {
    flex-direction: column;
  }

  .who_are_we__feature .jdbservices__container__feature-text {
    margin-top: 0.5rem;
  }


}

@media screen and (max-width: 350px) {
  .who_are_we__container .jdbservices__container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}
