.footer .jdbservices__container__feature-title {
    margin: 0px !important;
}

.footer .who_are_we__container .jdbservices__container__feature {
    display: flex;
    align-items: center;
}
.footer who_are_we__container{
    padding-bottom: 0rem !important;
}
.footer .who_are_we__container{
    padding-bottom: 3rem;
}

.footer .who_are_we__heading {
    justify-content: center;
    padding-top: -10px;
}

@media screen and (max-width: 350px) {
    .footer .jdbservices__container__feature  {
      margin-bottom: 50px !important;
    }
  }
  

