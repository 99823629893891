.blog__container__article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.blog__container__article_image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.blog__container__article_image img {
    width: 100%;
    height: 100%;
}

.blog__container__article__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1rem;
    height: 100%;
}

.blog__container__article__content p {
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 35px;
    font-style: italic;
    color: var(--color-text);
}

.blog__container__article__content h4 {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: 680;
    line-height: 35px;
    word-spacing: 5px;

    color: #fff;
    margin-bottom: 1rem;
    cursor: pointer;
}

.blog__container__article__content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .blog__container__article__content h4 {
        font-size: 18px;
        line-height: 30px;
        word-spacing: 5px;

    }
}