.possibility {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.possibility__image {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-right: 2rem;
}

.possibility__image img {
  width: 100%;
  height: 100%;
}

.possibility__content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.possibility__content h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #71e5ff;
}

.possibility__content h4:last-child {
  color: #ff8a71;
}

.possibility__content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 34px;
  line-height: 45px;
  margin: 1rem 0;
}

.possibility__content p {
  color: var(--color-text);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
  .possibility {
    flex-direction: column;
  }

  .possibility__image {
    margin: 1rem 0;
  }

  .possibility__content {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .possibility__image img {
    width: 100%;
    height: 100%;
  }
}
