.OurServices {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.OurServices__heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.OurServices__heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.OurServices__heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.OurServices__container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.OurServices__container p{
    font-size: 16px;
}
.get__started{
    cursor: pointer;
}
@media screen and (max-width: 990px) {
    .OurServices {
        flex-direction: column;
    }

    .OurServices__heading {
        margin: 0 0 2rem 0;
    }
}


