.jdbservices__container__feature {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;

  margin: 1rem;
}

.jdbservices__container__feature-title {
  flex: 1;
  max-width: 220px;
  margin-right: 3rem;
}

.jdbservices__container__feature-title h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #fff;
}

.jdbservices__container__feature-title div {
  width: 38px;
  height: 3px;
  background: var(--gradient-bar);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 0.25rem;
}

.jdbservices__container__feature-text {
  flex: 2;
  max-width: 390px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.jdbservices__container__feature-icons{
  flex: 2;
  max-width: 390px;
  display: flex;
  gap: 20px;
  padding-top: 10px;
}

.icon_contact {
  cursor: pointer;
  transition: color 0.3s ease;
}

.icon_contact:hover {
  color: #a9a9a9; /* Change to your desired hover color */
}
.jdbservices__container__feature-text p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;

  color: var(--color-text);
}

@media screen and (max-width: 550px) {


  .jdbservices__container__feature-text p {
    font-size: 12px;
  }

  .jdbservices__container__feature {
    margin: 1rem 0;
  }
}
